export const setIsOpen = (data) => dispatch => {
  console.log("SET_IS_OPEN");
  dispatch({
    type: "SET_IS_OPEN",
    payload: data,
  })
}

export const setisMicOff = (data) => dispatch => {
  console.log("SET_IS_MIC_OFF");
  dispatch({
    type: "SET_IS_MIC_OFF",
    payload: data,
  })
}

export const setIsPaused = (data) => dispatch => {
  console.log("SET_IS_PAUSED");
  dispatch({
    type: "SET_IS_PAUSED",
    payload: data,
  })
}

export const setIsCallInProgress = (data) => dispatch => {
  console.log("SET_IS_CALL_IN_PROGRESS");
  dispatch({
    type: "SET_IS_CALL_IN_PROGRESS",
    payload: data,
  })
}

export const setNumbers = (data) => dispatch => {
  console.log("SET_NUMBERS");
  dispatch({
    type: "SET_NUMBERS",
    payload: data,
  })
}

export const setDestinationNumber = (data) => dispatch => {
  console.log("SET_DESTINATION_NUMBER");
  dispatch({
    type: "SET_DESTINATION_NUMBER",
    payload: data,
  })
}

export const setDestinationName = (data) => dispatch => {
  console.log("setDestinationName dispatch");
  dispatch({
    type: "SET_DESTINATION_NAME",
    payload: data,
  })
}
export const setDid = (data) => dispatch => {
  console.log("SET_DID");
  dispatch({
    type: "SET_DID",
    payload: data,
  })
}

export const setCallType = (data) => dispatch => {
  console.log("SET_CALL_TYPE");
  dispatch({
    type: "SET_CALL_TYPE",
    payload: data,
  })
}

export const setCallStatus = (data) => dispatch => {
  console.log("SET_CALL_STATUS");
  dispatch({
    type: "SET_CALL_STATUS",
    payload: data,
  })
}

export const setUAList = (data) => dispatch => {
  console.log("SET_UA_LIST");
  dispatch({
    type: "SET_UA_LIST",
    payload: data,
  })
}

export const setRTCSessionData = (data) => dispatch => {
  console.log("SET_RTC_SESSION_DATA");
  dispatch({
    type: "SET_RTC_SESSION_DATA",
    payload: data,
  })
}

export const setDispositions = (data) => dispatch => {
  console.log("SET_DISPOSITIONS");
  dispatch({
    type: "SET_DISPOSITIONS",
    payload: data,
  })
}

export const setDisposition = (data) => dispatch => {
  console.log("SET_DISPOSITION");
  dispatch({
    type: "SET_DISPOSITION",
    payload: data,
  })
}


export const setTimer = (data) => dispatch => {
  console.log("SET_TIMER", data);
  dispatch({
    type: "SET_TIMER",
    payload: data,
  })
}


export const setRunTimer = (data) => dispatch => {
  console.log("SET_RUN_TIMER");
  dispatch({
    type: "SET_RUN_TIMER",
    payload: data,
  })
}

export const setCallId = (data) => dispatch => {
  console.log("SET_CALL_ID");
  dispatch({
    type: "SET_CALL_ID",
    payload: data,
  })
}

export const setShowDailNextButton = (data) => dispatch => {
  console.log("SET_SHOW_DAIL_NEXT_BUTTON");
  dispatch({
    type: "SET_SHOW_DAIL_NEXT_BUTTON",
    payload: data,
  })
}

export const setDailNextTimer = (data) => dispatch => {
  console.log("SET_DAIL_NEXT_TIMER");
  dispatch({
    type: "SET_DAIL_NEXT_TIMER",
    payload: data,
  })
}

export const setAgentStatus = (data) => dispatch => {
  console.log("setAgentStatus dispatch", data);
  dispatch({
    type: "SET_AGENT_STATUS",
    payload: data,
  })
}

export const setContactId = (data) => dispatch => {
  console.log("setAgentStatus dispatch", data);
  dispatch({
    type: "SET_CONTACT_ID",
    payload: data,
  })
}

export const setSelectedDailer = (data) => dispatch => {
  console.log("setSelectedDailer dispactch", data);
  dispatch({
    type: "SET_SELECTED_DAILER",
    payload: data,
  })
}

export const setAgentActiveCampaigns = (data) => dispatch => {
  console.log("setAgentActiveCampaigns dispatch", data);
  dispatch({
    type: "SET_AGENT_ACTIVE_CAMPAIGNS",
    payload: data,
  })
}

export const setSelectedCampaign = (data) => dispatch => {
  console.log("setSelectedCampaign dispatch", data);
  dispatch({
    type: "SET_SELECTED_CAMPAIGN",
    payload: data,
  })
}

export const setSelectedCampaignDetails = (data) => dispatch => {
  console.log("setSelectedCampaignDetails dispatch", data);
  dispatch({
    type: "SET_SELECTED_CAMPAIGN_DETAILS",
    payload: data,
  })
}

export const setShowCampaignExitScreen = (data) => dispatch => {
  console.log("setShowCampaignExitScreen dispatch", data);
  dispatch({
    type: "SET_SHOW_CAMPAIGN_EXIT_SCREEN",
    payload: data,
  })
}

export const setIsCampaignInProgress = (data) => dispatch => {
  console.log("setIsCampaignInProgress dispatch", data);
  dispatch({
    type: "IS_CAMPAIGN_IN_PROGRESS",
    payload: data,
  })
}

export const setIsAgentStatusUpdated = (data) => dispatch => {
  console.log("setIsAgentStatusUpdated dispatch", data);
  dispatch({
    type: "SET_IS_AGENT_STATUS_UPDATED",
    payload: data,
  })
}

export const setBatchId = (data) => dispatch => {
  console.log("setBatchId dispatch", data);
  dispatch({
    type: "SET_BATCH_ID",
    payload: data
  })
}

export const setCampaignCompletionStat = (data) => dispatch => {
  console.log("setCampaignCompletionStat dispatch", data);
  dispatch({
    type: "SET_CAMPAIGN_COMPLETION_STAT",
    payload: data,
  })
}

export const setCampaignId = (data) => dispatch => {
  console.log("setCampaignId dispatch", data);
  dispatch({
    type: "SET_CAMPAIGN_ID",
    payload: data,
  })
}