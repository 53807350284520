import Sidebar from '../../common/component/sidebar/Sidebar';
import Topbar from '../../common/component/topbar/Topbar';
import TabSearchBar from './TabSearchBar';
import { commonStyle } from '../../assets/css/css';
import { connect } from 'react-redux';
// import DataCard from '../component/DataCard';
import DataTable from '../../common/component/table/DataTable';
import Paginator from '../../common/component/pagination/PaginationComponent';
import { GET } from '../../services/HttpRequests';
import Online from '../../assets/images/smallCircleGreen.png';
import Offline from '../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../common/component/preLoader/PreLoader';
import { lightBlue } from '@material-ui/core/colors';
// import ReportSearchBar from '../component/ReportSearchBar';
import activeStatus from '../../assets/images/activeStatus.svg';

import tea from '../../assets/images/tea.svg';
import lunch from '../../assets/images/lunch.svg';
import meeting from '../../assets/images/meeting.svg';
import training from '../../assets/images/training.svg';
import biobreak from '../../assets/images/biobreak.svg';
import offline from '../../assets/images/offline.svg';
import away from '../../assets/images/away.svg';
import busy from "../../assets/images/busy.svg";

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function AgentsList(props) {
  /* AGENT ACTIVE STATUS */
  const optionsAgentActiveStatus = {
    'active': { label: 'Active', image: activeStatus, color: '#4daa3e' },
    'tea': { label: 'Tea', image: tea, color: '#196cca' },
    'lunch': { label: 'Lunch', image: lunch, color: '#196cca' },
    'meeting': { label: 'Meeting', image: meeting, color: '#196cca' },
    'training': { label: 'Training', image: training, color: '#196cca' },
    'biobreak': { label: 'Biobreak', image: biobreak, color: '#196cca' },
    'break': { label: 'Break', image: away, color: '#f8bb44' },
    'offline': { label: 'Offline', image: offline, color: '#939598' },
    'Not Logged In': { label: 'Offline', image: offline, color: '#939598' },
    'busy': { label: 'Busy', image: busy, color: '#F5221B' },
    'calling': { label: 'Dailing', image: activeStatus, color: '#4daa3e' },
  };
  const headers = [
    { key: 'totalCalls', label: 'TOTAL CALLS' },
    { key: 'totalAnsweredCalls', label: 'TOTAL CALLS - CONNECTED' },
    { key: 'totalIncomingCalls', label: 'INCOMING CALLS' },
    { key: 'answeredIncomingCalls', label: 'INCOMING CALLS - CONNECTED' },
    { key: 'totalOutgoingCalls', label: 'OUTGOING CALLS' },
    { key: 'answeredOutgoingCalls', label: 'OUTGOING CALLS - CONNECTED' },
    // { key: 'duration', label: 'ACTIVE DURATION' }
  ];

  function formatTime(seconds) {
    if (seconds === 0) {
      return "-";
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    else {
      formattedTime = `${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <DataTable.Table style={{ minWidth: '100%' }}>
        <DataTable.Head>
          <DataTable.Row>
            <DataTable.CellHead >#</DataTable.CellHead>
            <DataTable.CellHead>AGENT NAME</DataTable.CellHead>
            {headers.map(({ key, label }) => (
              <DataTable.CellHead
                key={key}
                style={{ cursor: "pointer" }}
                onClick={() => props?.sortBasedOnTotalCalls(key, props.sortConfig)}
              >
                {label}
                {props.sortConfig.key === key && (
                  <span style={{ color: '#196cca', fontSize: '9px', transform: 'translateY(-50%) scaleX(2)', }}>
                    {props.sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}
                  </span>
                )}
              </DataTable.CellHead>
            ))}
            <DataTable.CellHead  >
              STATUS
            </DataTable.CellHead>
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {props?.agentsList?.length > 0 &&
            props?.agentsList?.map((agent, index) => (
              <DataTable.Row id={agent._id} key={index}>
                <DataTable.Cell>{props?.currentPage + index}</DataTable.Cell>
                <DataTable.Cell>
                  {agent?.name}
                </DataTable.Cell>
                <DataTable.Cell>{agent?.totalCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.totalAnsweredCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.totalIncomingCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.answeredIncomingCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.totalOutgoingCalls || "-"}</DataTable.Cell>
                <DataTable.Cell>{agent?.answeredOutgoingCalls || "-"}</DataTable.Cell>
                {/* <DataTable.Cell>{
                  formatTime(agent?.duration || 0)
                }</DataTable.Cell> */}
                <DataTable.Cell >
                  <span
                    key={agent._id}
                    style={{
                      padding: '5px',
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      display: 'flex',
                      color: (optionsAgentActiveStatus[agent.status]?.color || "red"),
                    }}
                  >
                    {agent?.status !== 'Not Logged In' &&
                      <>
                        <img
                          src={optionsAgentActiveStatus[agent.status]?.image}
                          style={{ fill: optionsAgentActiveStatus[agent.status].color }}
                          alt=".."
                        />
                        &nbsp;&nbsp;</>}
                    {agent?.status?.toUpperCase() || 'error'}
                  </span>
                </DataTable.Cell>
              </DataTable.Row>
            ))}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  );
}

export function Agents(props) {
  const [agentsData, setAgentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0, 10));
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [campaignsList, setCampaignsList] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'totalCalls', direction: 'asc' });

  const tabs = [];
  tabs.push({
    title: `Agent \n Analytics`,
    onClick: () => props.history.push('/analytics'),
    active: true,
  });

  const sortBasedOnTotalCalls = (key, sortConfig, directData) => {
    let data = agentsData;
    if (directData) {
      data = directData;
    }
    let direction = "asc";

    if (sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setAgentsData(sortedData);
    setSortConfig({ key, direction });

  };

  const getTotalCalls = (incomingCalls, outgoingCalls) => {
    if (incomingCalls + outgoingCalls > 0) {
      return incomingCalls + outgoingCalls;
    }
    return 0;
  }


  const fetchAgentsListHandler = (filter = {}) => {
    setIsLoading(true);
    let data = { ...filter };
    console.log(JSON.stringify(data));
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'outbound/agent-metrics', data)
      .then((res) => {
        let agentsMetrics = res?.data?.response?.result;
        agentsMetrics = agentsMetrics.map(agent => {
          return {
            ...agent,
            totalCalls: getTotalCalls(agent?.totalIncomingCalls ?? 0, agent?.totalOutgoingCalls ?? 0),
            totalAnsweredCalls: getTotalCalls(agent?.answeredIncomingCalls ?? 0, agent?.answeredOutgoingCalls ?? 0)
          }
        });
        sortBasedOnTotalCalls('totalCalls', sortConfig, agentsMetrics);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchCampaignListHandler = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      pageNo: 1,
      pageSize: 10000000,
      fetchOnlyCampaignsList: true,
      nonDeleted: true
    };
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCampaignsList(res?.data?.response?.campaignData);
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    fetchAgentsListHandler();
    fetchCampaignListHandler();
  }, []);


  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar
            tabs={tabs}
            agentList={agentsData || []}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fetchAgentsListHandler={fetchAgentsListHandler}
            campaignsList={campaignsList}
          />
          {isLoading && <PreLoader />}
          {!isLoading && <AgentsList
            agentsList={agentsData}
            currentPage={currentPage}
            sortBasedOnTotalCalls={sortBasedOnTotalCalls}
            sortConfig={sortConfig}
          />}
          {/* <Paginator
            currentPage={1}
            lastPage={1}
          /> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(Agents));
