
export const setCallDetails = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_DETAILS",
    payload: data,
  })
}

export const setOutGoingCallsTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_OUTGOING_CALLS_TABLE_HEADERS",
    payload: data,
  })
}
export const setBatchCallId = (data) => dispatch => {
  dispatch({
    type: "SET_BATCH_CALL_ID",
    payload: data
  })
}

export const setCallId = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_ID",
    payload: data
  })
}

export const setCallStatus = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_STATUS",
    payload: data
  })
}

export const toggleAutoCalling = (data) => dispatch => {
  dispatch({
    type: "TOGGLE_AUTO_CALLING",
    payload: data
  })
}


export const setAutoCalling = (data) => dispatch => {
  dispatch({
    type: "SET_AUTO_CALLING",
    payload: data
  })
}

export const setSingleCallStatus = (data) => dispatch => {
  console.log("setSingleCallStatus dispacther")
  dispatch({
    type: "SINGLE_CALL_STATUS",
    payload: data
  })
}

export const setDestinationNumber = (data) => dispatch => {
  dispatch({
    type: "SET_DESTINATION_NUMBER",
    payload: data,
  })
}

export const setDestinationName = (data) => dispatch => {
  dispatch({
    type: "SET_DESTINATION_NAME",
    payload: data,
  })
}

export const setRTCSession = (data) => dispatch => {
  dispatch({
    type: "SET_RTC_SESSION",
    payload: data,
  })
}

export const setCallFilterLeads = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_FILTER_LEADS",
    payload: data
  })
}

export const setRTCCallStatus = (data) => dispatch => {
  dispatch({
    type: "SET_RTC_CALL_STATUS",
    payload: data
  })
}

export const setHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_HEADERS",
    payload: data
  })
}

export const setCampaignId = (data) => dispatch => {
  dispatch({
    type: "SET_CAMPAIGN_ID",
    payload: data,
  })
}

export const setWebRtcConnectionStatus = (data) => dispatch => {
  dispatch({
    type: "SET_WEBRTC_CONN",
    payload: data,
  })
}
export const setDialerCallId = (data) => dispatch => {
  dispatch({
    type: "SET_DIALER_CALLID",
    payload: data,
  })
}
export const setIsSingleCall = (data) => dispatch => {
  dispatch({
    type: "SET_IS_SINGLE_CALL",
    payload: data,
  })
}
export const changeDispostionStatus = (data) => dispatch => {
  dispatch({
    type: "DISPOSITION_STATUS",
    payload: data,
  })
}
export const changeAgentActiveStatus = (data) => dispatch => {
  dispatch({
    type: "AGENT_ACTIVE_STATUS",
    payload: data,
  })
}

export const setWebRtcSessions = (data) => dispatch => {
  dispatch({
    type: "SET_WEBRTC_SESSIONS",
    payload: data,
  })
}

export const setDomainsOfCampaign = (data) => dispatch => {
  dispatch({
    type: "SET_DOMAINS_OF_CAMPAIGN",
    payload: data,
  })
}

export const setCallType = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_TYPE",
    payload: data,
  })
}

export const setCallLogsTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_CALL_LOGS_TABLE_HEADERS",
    payload: data,
  })
}

export const setCampaignCallHistoryTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_CAMPAIGN_CALL_HISTORY_TABLE_HEADERS",
    payload: data,
  })
}

export const setCampaignCallsTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_CAMPAIGN_CALLS_TABLE_HEADERS",
    payload: data,
  })
}

export const setAgentCampaignCallsTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_AGENT_CAMPAIGN_CALLS_TABLE_HEADERS",
    payload: data
  })
}

export const setStaticCampaignTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_STATIC_CAMPAIGN_TABLE_HEADERS",
    payload: data,
  })
}

export const setDynamicCampaignTableHeaders = (data) => dispatch => {
  dispatch({
    type: "SET_DYNAMIC_CAMPAIGN_TABLE_HEADERS",
    payload: data,
  })
}

export const setAgentStatus = (data) => dispatch => {
  console.log("setAgentStatus dispatch", data);
  dispatch({
    type: "SET_AGENT_STATUS",
    payload: data,
  })
}

export const setCampaignTableHeaders = (data) => dispatch => {
  console.log("setCampaignTableHeaders dispatch", data);
  dispatch({
    type: "SET_CAMPAIGNS_TABLE_HEADERS",
    payload: data,
  })
}