import React from 'react';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import AccountDeactivatedPage from './pages/accountDeactivate/container/AccountDeactivatedPage';
import AccountSettingsPage from './pages/setting/container/AccountSettingsPage';
import OffHoursPage from './pages/setting/container/OffHoursPage';
import AdminSignupPage from './pages/adminSignup/container/AdminSignupPage';
import CallLogsPage from './pages/collLogs/container/CallLogsPage';
import NumberMaskingBindingsPage from './pages/NumberMasking/container/NumberMaskingBindingsPage';
import NumberMaskingCallLogsPage from './pages/NumberMasking/container/NumberMaskingCallLogsPage';
import NumberMaskingDidListPage from './pages/NumberMasking/container/NumberMaskingDidsPage';
import DoosraNumbersPage from './pages/numbers/container/DoosraNumbersPage';
import CallerTunesPage from './pages/callertune/container/CallerTunePage.js';
import LoginPage from './pages/login/container/LoginPage';
import MaintainancePage from './pages/maintainance/container/MaintainancePage';
import PageNotFound from './common/component/pageNotFound/PageNotFound';
import ResetPasswordPage from './pages/resetPassword/container/ResetPasswordPage';
import SMSInboxPage from './pages/smsInbox/container/SMSInboxPage';
import UserSignupPage from './pages/signup/container/UserSignupPage';
import UsersPage from './pages/Users/container/UsersPage';
import IVRPage from './pages/callRouting/container/IVRPage';
import Analytics from "./pages/analytics/index";
import { useAuth } from './providers/AuthProvider';
import OutgoingCallLogsPage from './pages/collLogs/container/OutgoingCallLogsPage';
import DIDCallBackListPage from './pages/collLogs/container/DIDCallBackListPage';
import CallBlackListPage from './pages/collLogs/container/CallBlackListPage';
import DoosraOutgoingNumbersPage from './pages/numbers/container/DoosraOutgoingNumbersPage';
import SMSWebhookPage from './pages/webhooks/container/SMSWebhookPage';
import CallWebhookPage from './pages/webhooks/container/CallWebhooksPage';
import OutgoingCallWebhookPage from './pages/webhooks/container/OutgoingCallWebhooksPage';
import VoiceApiWebhookPage from './pages/webhooks/container/VoiceApiWebhookPage.js';
import { useTheme } from './providers/customeThemeProvider';
import { darkColorSchema, lightColorSchema } from './utility/colourSchema';
import ContactsPage from './pages/contacts/container/ContactsPage';
import AutoAttendantPage from './pages/autoAttendant/container/AutoAttendantPage';
import RecoveryCallingPage from './pages/recoveryCalling/container/RecoveryCallingPage';
import RecoveryXReportPage from './pages/recoveryCalling/container/RecoveryXReportsPage';
import RecoveryChartsPage from './pages/recoveryCalling/container/RecoveryChartsPage';
import RecoveryCallLogsPage from './pages/recoveryCalling/container/RecoveryCallLogsPage';
import VoiceBroadcastDetails from './pages/broadcastVoice/container/VoiceBroadcastDetails';
import MessageBroadcastDetails from './pages/broadcastMessage/container/MessageBroadcastDetails';
import MessageBroadcastsList from './pages/broadcastMessage/container/MessageBroadcastsList';
import CreateMessageBroadcastPage from './pages/broadcastMessage/container/CreateMessageBroadcastPage';
import VoiceBroadcastsList from './pages/broadcastVoice/container/VoiceBroadcastsList';
import VoiceApiList from './pages/broadcastVoice/container/VoiceApiList.js';
import CreateVoiceBroadcastPage from './pages/broadcastVoice/container/CreateVoiceBroadcastPage';
import CreateVoiceApiPage from './pages/broadcastVoice/container/CreateVoiceApiPage.js';
import RecoveryXDashboard from './pages/recoveryX/container/RecoveryXDashboard';
import RecoveryXCampaigns from './pages/recoveryX/container/RecoveryXCampaigns';
import RecoveryXAgents from './pages/recoveryX/container/RecoveryXAgents';
import RecoveryXCreateCampaign from './pages/recoveryX/container/RecoveryXCreateCampaign';
import RecoveryXCampaignDetails from './pages/recoveryX/container/RecoveryXCampaignDetails';
import RecoveryXAgentDetails from './pages/recoveryX/container/RecoveryXAgentDetails';
import RecoveryXCampaignReport from './pages/recoveryX/container/RecoveryXCampaignReport';
import RecoveryXCampaignView from './pages/recoveryX/container/RecoveryXCampaignView';
import RecoveryXAgentCampaigns from './pages/recoveryX/container/RecoveryXAgentCampaigns';
import RecoveryXAgentCampaignsV2 from './pages/recoveryX/container/RecoveryXAgentCampaignsV2';
import RecoveryXAgentCampaignView from './pages/recoveryX/container/RecoveryXAgentCampaignView';
import RecoveryXAgentStaticCampaignView from './pages/recoveryX/container/RecoveryXAgentStaticCampaignView';
import RecoveryXCampaignLeads from './pages/recoveryX/container/RecoveryXCampaignLeads';
import RecoveryXAgentReports from './pages/recoveryX/container/RecoveryXAgentReports';
import RecoveryXCalls from './pages/recoveryX/container/RecoveryXCalls';
import RecoveryXDND from './pages/recoveryX/container/RecoveryXDND';
import RecoveryXAgentDynamicCampaignView from './pages/recoveryX/container/RecoveryXAgentDynamicCampaignView';
import { SignUpPage } from './pages/orgsignup/container/signup';
import VerifiedContact from './pages/trailPeriod/container/VerifiedContact';
import { UploadKyc } from './pages/kyc/container/uploadKyc';
import { EndRun } from './actions/onBoardingTourActions';
import PlansPage from './pages/subscriptionPlans/PlansPage';
import PreLoader from './common/component/preLoader/PreLoader';
import FreetrialUpgrade from './common/component/freetrialUpgrade/index.js';
import WebsiteShiftedPage from './pages/websiteShifted/container/WebsiteShiftedPage';
import VoiceApiDetails from './pages/broadcastVoice/container/VoiceApiDetails.js';
import RecoveryXAgentDynamicCampaignViewV2 from './pages/recoveryX/container/RecoveryXAgentDynamicCampaignViewV2';
import RecoveryXAgentStaticCampaignViewV2 from './pages/recoveryX/container/RecoveryXAgentStaticCampaignViewV2';
import RecoveryXCreateCampaignV2 from './pages/recoveryX/container/RecoveryXCreateCampaignV2';
import StickyDialer from './pages/stickyDialerV3/StickyDialer';
import NumberMaskingCallDetailsWebhookPage from './pages/webhooks/container/NumberMaskingCallDetailsWebhookPage.js';
import NumberMaskingWebhookPage from './pages/webhooks/container/NumberMaskingWebhookPage.js';
import PaymentHistoryPage from './pages/setting/container/PaymentHistoryPage.js';
import KYCPage from './pages/setting/container/KYCPage.js';
import Dialer from './pages/Dialer/StickyDialer';
import Dashboard from './pages/cloudCallCenter/container/Dashboard';
import Campaigns from './pages/cloudCallCenter/container/Campaigns';
import Agents from './pages/cloudCallCenter/container/Agents';
import Calls from './pages/cloudCallCenter/container/Calls';
import BlackList from './pages/cloudCallCenter/container/BlackList';
import Campaign from './pages/cloudCallCenter/container/Campaign';
import CampaignLeads from './pages/cloudCallCenter/container/CampaignLeads';
import CampaignCalls from './pages/cloudCallCenter/container/CampaignCalls';
import CreateCampaign from './pages/cloudCallCenter/container/CreateCampaign';
import AgentCampaigns from './pages/cloudCallCenter/container/AgentCampaigns';
import StaticCampaign from './pages/cloudCallCenter/container/StaticCampaign';
import CampaignCallHistory from './pages/cloudCallCenter/container/CampaignCallHistory';
import AgentCampaignCalls from './pages/cloudCallCenter/container/AgentCampaignCalls';
import DynamicCampaign from './pages/cloudCallCenter/container/DynamicCampaign';
import AgentCampaignMetrics from './pages/cloudCallCenter/container/AgentCampaignMetrics';
import AgentCampaignMetricCalls from './pages/cloudCallCenter/container/AgentCampaignMetricCalls';
import ZohoPaymentStatus from './pages/zohoPaymentStatus/ZohoPaymentStatus.js';
import InitialPaymentPopup from './common/component/initialPaymentPopup';
import KYCPendingStatus from './common/component/KycStatusPage';
import AccountLocked from './common/component/accountLocked/index.js';
import KYCPending from './pages/kycPending/KYCPending.js';
import PlanExpired from './common/component/plan-expired/PlanExpired.js';
import { logoutHandler } from './utility/utility.js';
import TrialPeriod from './common/component/trialPeriod/index.js';
import CallSettingsPage from './pages/setting/container/CallSettingsPage';
import CampaignsV2 from './pages/campaigns/container/Campaigns';
import CallsV2 from "./pages/campaigns/container/Calls";
import CampaignLeadsV2 from "./pages/campaigns/container/CampaignLeads";
import CreateCampaignV2 from "./pages/campaigns/container/CreateCampaign";
import CampaignReportTableV2 from "./pages/campaigns/container/CampaignReportTable";
import AgentCampaignLeads from "./pages/campaigns/container/AgentCampaignLeads";

const HOST = ['business.doosra.com'];

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();
  const { themeType } = useTheme();
  const location = useLocation();
  const isPlanAvailable = JSON.parse(localStorage.getItem('is_plan_available'));
  const isOrganisationActive =
    localStorage.getItem('organisation_status') === 'ACTIVE' ? true : false;
  const planExpired = JSON.parse(localStorage.getItem('is_plan_expired'));
  const isKYCCompleted = JSON.parse(localStorage.getItem('is_kyc_completed'));
  console.log(
    'planExpired - ',
    planExpired,
    ', isKycCompleted - ',
    isKYCCompleted
  );

  if (
    isLoggedIn &&
    JSON.parse(localStorage.getItem('is_plan_expired')) &&
    localStorage.getItem('doosra-biz-user-role') === 'USER'
  ) {
    //logout user
    logoutHandler();
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        switch (true) {
          case !isPlanAvailable &&
            isOrganisationActive &&
            (rest.path === '/kyc' || rest.path === '/payment-history'):
            return (
              <ThemeProvider
                theme={
                  themeType === 'light' ? lightColorSchema : darkColorSchema
                }
              >
                <CallLogsPage {...props} />
              </ThemeProvider>
            );
          // case planExpired && rest.path === '/account-settings':
          //   return (
          //     <ThemeProvider
          //       theme={
          //         themeType === 'light' ? lightColorSchema : darkColorSchema
          //       }
          //     >
          //       <AccountSettingsPage {...props} />
          //     </ThemeProvider>
          //   );
          case !isKYCCompleted:
            return (
              <ThemeProvider
                theme={
                  themeType === 'light' ? lightColorSchema : darkColorSchema
                }
              >
                <KYCPending {...props} />
              </ThemeProvider>
            );
          // case planExpired:
          //   return null;  Handle scenario where plan expired but no specific component to render
          default:
            return (
              <ThemeProvider
                theme={
                  themeType === 'light' ? lightColorSchema : darkColorSchema
                }
              >
                <Component {...props} />
              </ThemeProvider>
            );
        }
      }}
    />
  );
};

function MainComponent(props) {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  if (props.isUnderMaintainance) {
    return <MaintainancePage message={props.maintainanceMessage} />;
  }

  let host = window.location.host;

  function isDateGreater() {
    const currentDate = new Date();
    const blockByDate = new Date('2023-11-28');
    if (currentDate > blockByDate) return true;
    return false;
  }

  if (HOST.includes(host) && isDateGreater()) {
    return <WebsiteShiftedPage />;
  }

  const shouldRenderViewPlan = location.pathname !== '/plans';

  const DelayedPageNotFound = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setShow(true), 1000); // wait for 1 second
      return () => clearTimeout(timer); // clear timer on component unmount
    }, []);

    return show ? <PageNotFound /> : <PreLoader />;
  };

  return (
    <div>
      {isLoggedIn && <TrialPeriod />}
      {localStorage.getItem('doosra-biz-user-role') === 'USER' &&
        (localStorage.getItem('is_v2_static_campaigns_enabled') === 'true' ||
          localStorage.getItem('is_v2_dynamic_campaigns_enabled') === 'true' ? (
          <StickyDialer />
        ) : localStorage.getItem('is_cloud_call_center_enabled') === 'true' ? (
          <Dialer />
        ) : null)}
      {/* <NewUrl /> */}
      {/* {shouldRenderViewPlan && <ViewPlan />} */}
      <FreetrialUpgrade />
      {/* {localStorage.getItem('doosra-biz-organisation-id') &&
        location.pathname !== '/payment' &&
        props.accountStatus === null && <PreLoader />} */}

      {/* {localStorage.getItem('doosra-biz-organisation-id') &&
        location.pathname !== '/payment' &&
        props.accountStatus === 'INACTIVE' && <InitialPaymentPopup />} */}
      {/* <AccountLocked /> */}
      <PlanExpired
        open={
          isLoggedIn &&
          JSON.parse(localStorage.getItem('is_plan_expired')) &&
          JSON.parse(localStorage.getItem('org_in_grace_period')) &&
          localStorage.getItem('organisation_status') === 'INACTIVE' &&
          location.pathname !== '/payment'
        }
      />
      <Switch>
        <Route exact path="/" component={LoginPage} />
        {/* <Route exact path="/selfsignup" component={LoginPage} /> */}
        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/signup/admin" component={AdminSignupPage} />
        <Route exact path="/signup/user" component={UserSignupPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/plans" component={PlansPage} />
        <PrivateRoute exact path="/upload-kyc" component={UploadKyc} />
        <Route exact path="/forgot-password" component={ResetPasswordPage} />
        {/* <Redirect from="/doosra-numbers" to="/virtual-numbers" /> */}
        <Redirect
          from="/outgoing-doosra-numbers"
          to="/outgoing-virtual-numbers"
        />
        {/* <PrivateRoute exact path="/sms-inbox" component={SMSInboxPage} /> */}
        <PrivateRoute
          exact
          path="/virtual-numbers"
          component={DoosraNumbersPage}
        />
        <PrivateRoute
          exact
          path="/outgoing-virtual-numbers"
          component={DoosraOutgoingNumbersPage}
        />
        <PrivateRoute exact path="/ivr" component={IVRPage} />
        <PrivateRoute exact path="/call-logs" component={CallLogsPage} />
        <PrivateRoute
          exact
          path="/did-call-back"
          component={DIDCallBackListPage}
        />
        <PrivateRoute exact path="/black-list" component={CallBlackListPage} />
        <PrivateRoute
          exact
          path="/outgoing-call-logs"
          component={OutgoingCallLogsPage}
        />
        <PrivateRoute exact path="/trial-period" component={VerifiedContact} />
        <PrivateRoute exact path="/users" component={UsersPage} />
        <PrivateRoute exact path="/contacts" component={ContactsPage} />
        <PrivateRoute
          exact
          path="/number-masking-call-logs"
          component={NumberMaskingCallLogsPage}
        />
        <PrivateRoute
          exact
          path="/number-masking-did"
          component={NumberMaskingDidListPage}
        />
        <PrivateRoute
          exact
          path="/number-masking-bindings"
          component={NumberMaskingBindingsPage}
        />
        <PrivateRoute
          exact
          path="/recovery-calling"
          component={RecoveryCallingPage}
        />
        <PrivateRoute
          exact
          path="/recovery-x-dashboard"
          component={RecoveryXDashboard}
        />
        <PrivateRoute
          exact
          path="/recovery-x-agent-report"
          component={RecoveryXAgentReports}
        />
        <PrivateRoute
          exact
          path="/recovery-x-campaigns"
          component={RecoveryXCampaigns}
        />
        <PrivateRoute
          exact
          path="/recovery-x-calls"
          component={RecoveryXCalls}
        />
        {localStorage.getItem('is_v2_static_campaigns_enabled') === 'true' ||
          localStorage.getItem('is_v2_dynamic_campaigns_enabled') === 'true' ? (
          <PrivateRoute
            exact
            path="/campaigns"
            component={RecoveryXAgentCampaignsV2}
          />
        ) : (
          <PrivateRoute
            exact
            path="/campaigns"
            component={RecoveryXAgentCampaigns}
          />
        )}
        <PrivateRoute
          exact
          path="/campaign"
          component={RecoveryXAgentCampaignView}
        />
        {localStorage.getItem('is_v2_static_campaigns_enabled') === 'true' ? (
          <PrivateRoute
            exact
            path="/campaign/static"
            component={RecoveryXAgentStaticCampaignViewV2}
          />
        ) : (
          <PrivateRoute
            exact
            path="/campaign/static"
            component={RecoveryXAgentStaticCampaignView}
          />
        )}
        <PrivateRoute exact path="/recovery-x-dnd" component={RecoveryXDND} />
        {localStorage.getItem('is_v2_dynamic_campaigns_enabled') === 'true' ? (
          <PrivateRoute
            exact
            path="/campaign/dynamic"
            component={RecoveryXAgentDynamicCampaignViewV2}
          />
        ) : (
          <PrivateRoute
            exact
            path="/campaign/dynamic"
            component={RecoveryXAgentDynamicCampaignView}
          />
        )}
        <PrivateRoute
          exact
          path="/recovery-x-campaign"
          component={RecoveryXCampaignDetails}
        />
        <PrivateRoute
          exact
          path="/recovery-x-campaign/leads"
          component={RecoveryXCampaignLeads}
        />
        <PrivateRoute
          exact
          path="/recovery-x-agents"
          component={RecoveryXAgents}
        />
        <PrivateRoute
          exact
          path="/recovery-x-agent"
          component={RecoveryXAgentDetails}
        />
        {localStorage.getItem('is_v2_static_campaigns_enabled') === 'true' ||
          localStorage.getItem('is_v2_dynamic_campaigns_enabled') === 'true' ? (
          <PrivateRoute
            exact
            path="/recovery-x-create-campaign"
            component={RecoveryXCreateCampaignV2}
          />
        ) : (
          <PrivateRoute
            exact
            path="/recovery-x-create-campaign"
            component={RecoveryXCreateCampaign}
          />
        )}
        <PrivateRoute
          exact
          path="/recovery-x-campaign-report"
          component={RecoveryXCampaignReport}
        />
        <PrivateRoute
          exact
          path="/recovery-x-campaign-view"
          component={RecoveryXCampaignView}
        />
        <PrivateRoute exact path="/report" component={RecoveryXReportPage} />
        <PrivateRoute exact path="/charts" component={RecoveryChartsPage} />
        <PrivateRoute
          exact
          path="/recovery-calls"
          component={RecoveryCallLogsPage}
        />
        <PrivateRoute
          exact
          path="/account-deactivated"
          component={AccountDeactivatedPage}
        />
        <PrivateRoute
          exach
          path="/account-settings"
          component={AccountSettingsPage}
        />
        <PrivateRoute exach path="/off-hours" component={OffHoursPage} />
        <PrivateRoute
          exach
          path="/call-settings"
          component={CallSettingsPage}
        />
        <PrivateRoute
          exach
          path="/payment-history"
          component={PaymentHistoryPage}
        />

        <PrivateRoute exach path="/kyc" component={KYCPage} />
        <PrivateRoute exach path="/kyc-pending" component={KYCPending} />

        <PrivateRoute exach path="/caller-tunes" component={CallerTunesPage} />
        {/* <PrivateRoute exact path="/sms-webhook" component={SMSWebhookPage} /> */}
        <PrivateRoute exact path="/call-webhook" component={CallWebhookPage} />
        <PrivateRoute
          exact
          path="/auto-attended"
          component={AutoAttendantPage}
        />
        <PrivateRoute
          exact
          path="/voice-api-webhook"
          component={VoiceApiWebhookPage}
        />
        <PrivateRoute
          exact
          path="/number-masking-api-webhook"
          component={NumberMaskingWebhookPage}
        />
        <PrivateRoute
          exact
          path="/number-masking-call-details-webhook"
          component={NumberMaskingCallDetailsWebhookPage}
        />
        <PrivateRoute
          exact
          path="/outgoing-call-webhook"
          component={OutgoingCallWebhookPage}
        />
        {/* <Route path="*" component={PageNotFound} /> */}
        <PrivateRoute
          exact
          path="/create-voice-broadcast"
          component={CreateVoiceBroadcastPage}
        />
        <PrivateRoute
          exact
          path="/create-voice-api"
          component={CreateVoiceApiPage}
        />
        <PrivateRoute
          exact
          path="/voice-broadcasts-list"
          component={VoiceBroadcastsList}
        />
        <PrivateRoute
          exact
          path="/voice-broadcast-details"
          component={VoiceBroadcastDetails}
        />
        <PrivateRoute
          exact
          path="/voice-api-details"
          component={VoiceApiDetails}
        />
        <PrivateRoute exact path="/voice-api-list" component={VoiceApiList} />
        <PrivateRoute
          exact
          path="/create-sms-broadcast"
          component={CreateMessageBroadcastPage}
        />
        <PrivateRoute
          exact
          path="/sms-broadcasts-list"
          component={MessageBroadcastsList}
        />
        <PrivateRoute
          exact
          path="/sms-broadcast-details"
          component={MessageBroadcastDetails}
        />
        <PrivateRoute exact path="/analytics" component={Analytics} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/cc-campaigns" component={Campaigns} />
        <PrivateRoute exact path="/agents" component={Agents} />
        <PrivateRoute exact path="/calls" component={Calls} />
        <PrivateRoute exact path="/blacklist" component={BlackList} />
        <PrivateRoute exact path="/cc-campaign" component={Campaign} />
        <PrivateRoute exact path="/leads" component={CampaignLeads} />
        <PrivateRoute exact path="/campaign-calls" component={CampaignCalls} />
        <PrivateRoute
          exact
          path="/create-campaign"
          component={CreateCampaign}
        />
        <PrivateRoute
          exact
          path="/agent-campaigns"
          component={AgentCampaigns}
        />
        <PrivateRoute
          exact
          path="/static-campaign"
          component={StaticCampaign}
        />
        <PrivateRoute
          exact
          path="/dynamic-campaign"
          component={DynamicCampaign}
        />
        <PrivateRoute
          exact
          path="/campaign-call-logs"
          component={CampaignCallHistory}
        />
        <PrivateRoute
          exact
          path="/agent-history"
          component={AgentCampaignCalls}
        />
        <PrivateRoute
          exact
          path="/agent-campaigns-details"
          component={AgentCampaignMetrics}
        />
        <PrivateRoute
          exact
          path="/agent-campaigns-calls"
          component={AgentCampaignMetricCalls}
        />
        <PrivateRoute
          exact
          path="/campaigns-v2"
          component={CampaignsV2}
        />
        <PrivateRoute
          exact
          path="/calls-v2"
          component={CallsV2}
        />
        <PrivateRoute
          exact
          path="/agent-leads"
          component={AgentCampaignLeads}
        />
        <PrivateRoute
          exact
          path="/campaign-leads"
          component={CampaignLeadsV2}
        />
        <PrivateRoute
          exact
          path="/campaign-report"
          component={CampaignReportTableV2}
        />
        <PrivateRoute
          exact
          path="/create"
          component={CreateCampaignV2}
        />
        <Route exact path="/payment" component={ZohoPaymentStatus} />
        <Route path="*" component={DelayedPageNotFound} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isUnderMaintainance: state.system.isUnderMaintainance,
  maintainanceMessage: state.system.maintainanceMessage,
  accountStatus: state.topbar?.accountStatus,
});

export default withRouter(connect(mapStateToProps, { EndRun })(MainComponent));
