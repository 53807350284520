import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Dispositions from '../component/DispositionsPopup';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import { connect } from 'react-redux';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { DELETE, GET, PUT, POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AddBlackList from "../component/AddBlackListPopup";
import { isSuperAdminWithPermission } from '../../../utility/utility';
const moment = require('moment-timezone');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const role = localStorage.getItem('doosra-biz-user-role');
const organisationId = localStorage.getItem('doosra-biz-organisation-id');
const planAvailable = localStorage.getItem('is_plan_available');
let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
const showPaymentHistory = localStorage.getItem('show_payment_history');

const CallSettings = (props) => {
  const [dispositions, setDispositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    {
      title: 'Account',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/account-settings'),
    },
    {
      title: 'Operating Schedule',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/off-hours'),
    },
    {
      title: 'Call Settings',
      active: true,
      subtitle: ``,
      onClick: () => props.history.push('/call-settings'),
    },
  ];

  if (planAvailable === true || planAvailable === 'true') {
    if (showPaymentHistory) {
      tabs.push(
        {
          title: 'Payment History',
          active: false,
          subtitle: ``,
          onClick: () => props.history.push('/payment-history'),
        });
    }
    tabs.push({
      title: 'KYC',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/kyc'),
    });
  }

  useEffect(() => {
    getDispositions();
  }, []);

  const getDispositions = () => {
    const data = {
      organisationId
    };
    setIsLoading(true);
    return GET(
      cloudCallCenterUrl + 'disposition',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setIsLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setIsLoading(false);
        return false;
      });
  }
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          {role !== 'USER' && !isFreeTrail && <TabSearchBar tabs={tabs} />}
          <Container>
            <Title>Call settings</Title>
            <Subtitle>Configure calling modes, dispositions and a blacklist to optimise your call workflow</Subtitle>

            <SettingsGrid>
              <CallingModes
                pstnAccessEnabled={localStorage.getItem('pstn_enabled') === 'true' ? true : false}
                webrtcAccessEnabled={localStorage.getItem('webrtc_enabled') === 'true' ? true : false}
              />
              {!isLoading && <Card>
                <Dispositions
                  refreshList={getDispositions}
                  dispositions={dispositions}
                />
              </Card>}
              {isLoading && <PreLoader />}
            </SettingsGrid>
            {props?.callForwardingStatus && (<StickyAgent isOrgOutboundCallEnabled={props?.isOrgOutboundCallEnabled} />)}
            <Blacklist />
          </Container>
        </div>
      </div>
    </div>
  );
};

const CallingModes = (props) => {
  const [pstn, setPstn] = useState(props.pstnAccessEnabled);
  const [webrtc, setWebRtc] = useState(props.webrtcAccessEnabled);


  const onChange = (key) => {
    if (key === 'pstn') {
      setPstn(prev => !prev);
    }
    if (key === 'webrtc') {
      setWebRtc(prev => !prev);
    }
  }

  useEffect(() => {
    console.log(pstn, webrtc);
  }, [pstn, webrtc]);

  return (
    <Card>
      <CardTitle>Calling modes</CardTitle>
      <CardText>Select from PSTN or WebRTC or both call types to suit your communication needs. PSTN calls connect to traditional landlines and mobile phones, while WebRTC calls enable real-time communication directly within your browser.</CardText>
      <CheckboxContainer>
        <Checkbox
          type="checkbox"
          id="pstn"
          checked={pstn}
          disabled={true}
          // disabled={!props.pstnAccessEnabled}
          onChange={() => onChange('pstn')}
        />
        <CheckboxLabel htmlFor="pstn">PSTN</CheckboxLabel>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox
          type="checkbox"
          id="webrtc"
          checked={webrtc}
          disabled={true}
          // disabled={!props.webrtcAccessEnabled}
          onChange={() => onChange('webrtc')}
        />
        <CheckboxLabel htmlFor="webrtc">WebRTC</CheckboxLabel>
      </CheckboxContainer>

    </Card>
  );
};

const Blacklist = () => {
  const [blacklistData, setBlackListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const deleteDNDNumber = (dndId) => {
    const data = {
      docId: dndId
    }
    DELETE(`${cloudCallCenterUrl}blacklist`, {}, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        fetchDataHandler();
        toast.success('BlackList Number Deleted');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const fetchDataHandler = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      searchType: 'isdeleted',
      searchText: 'false'
    }
    setIsLoading(true);
    GET(`${cloudCallCenterUrl}blacklist`, data)
      .then((res) => {
        setBlackListData(res?.data?.response || []);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataHandler();
  }, [])

  return (
    <BlacklistCard>
      <BlacklistHeader>
        <div>
          <CardTitle>Blacklist</CardTitle>
          <CardText>Add numbers to your blacklist to prevent unwanted outgoing calls and block incoming calls from specific numbers.</CardText>
        </div>
        <AddBlackList refreshList={fetchDataHandler} />
      </BlacklistHeader>

      {isLoading ? <PreLoader /> :
        blacklistData.length ?

          <TableContainer>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th >Date added</th>
                  <th >Name</th>
                  <th >Number</th>
                  {/* <th >Blocked</th> */}
                  <th >Blocked For</th>
                  <th >Actions</th>
                </tr>
              </thead>
              <tbody>
                {blacklistData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.createdAt ? moment(item.createdAt).tz('Asia/Kolkata').format('DD/MM/YY') : "-"}</td>
                    <td>{item.name ? item.name[0].toUpperCase() + item.name.slice(1).toLowerCase() : "-"}</td>
                    <td>{item.number}</td>
                    {/* <td>{item?.isBlocked ? 'yes' : 'no'}</td> */}
                    <td>
                      {item?.blockedFor ? item?.blockedFor.join(",") : "-"}
                    </td>
                    <td>
                      <img alt=""
                        onClick={() => { deleteDNDNumber(item._id.toString()) }}
                        style={{ "cursor": "pointer", }}
                        src={Delete} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer> : <div style={{ color: 'brown', fontWeight: 500, textAlign: 'center' }}>No BlackList numbers</div>
      }
    </BlacklistCard>
  );
};

const StickyAgent = (props) => {
  const [outgoingEnable, setOutgoingEnable] = useState(false);
  const [outgoingDuration, setOutgoingDuration] = useState(null);
  const [outgoingError, setOutgoingEroor] = useState("");
  const [incomingEnable, setIncomingEnable] = useState(false);
  const [incomingDuration, setIncomingDuration] = useState(null);
  const [incomingError, setIncomingError] = useState("");

  const incomingEnableHandler = () => {
    setIncomingEnable(!incomingEnable);
    if (!incomingEnable)
      setIncomingDuration(5);
    if (incomingEnable)
      setIncomingDuration('');
  }
  const outgoingEnableHandler = () => {
    setOutgoingEnable(!outgoingEnable);
    if (!outgoingEnable)
      setOutgoingDuration(24);
    if (outgoingEnable)
      setOutgoingDuration('');
  }
  const updateData = () => {
    setIncomingError("");
    setOutgoingEroor("");
    if (incomingEnable && incomingDuration <= 0) {
      setIncomingError("Please Enter Duration");
      return true;
    }
    if (outgoingDuration && outgoingDuration <= 0) {
      setOutgoingEroor("Please Enter Duration");
      return true;
    }
    if (incomingDuration > 0 && !incomingEnable) {
      setIncomingEnable(true);
    }
    if (outgoingDuration > 0 && !outgoingEnable) {
      setOutgoingEnable(true);
    }
    if (incomingDuration > 60) {
      setIncomingError("Duration should be less than 60 minutes");
      return true;
    }
    if (outgoingDuration > 72) {
      setOutgoingEroor("Duration should be less than 72 hours");
      return true;
    }
    const data = { organisationId };
    if (incomingEnable)
      data.incomingEnable = incomingEnable;
    if (outgoingEnable)
      data.outgoingEnable = outgoingEnable;
    if (outgoingDuration)
      data.outgoingDuration = outgoingDuration ? parseInt(outgoingDuration) : outgoingDuration;
    if (incomingDuration)
      data.incomingDuration = incomingDuration ? parseInt(incomingDuration) : incomingDuration;

    POST(`${orgUrl}v1/organisation/sticky-agent`, data)
      .then((res) => {
        fetchData();
        toast.success('Data Saved Successfully');
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  const resetData = () => {
    setIncomingError("");
    setOutgoingEroor("");
    setIncomingEnable(true);
    setOutgoingEnable(true);
    setOutgoingDuration(24);
    setIncomingDuration(5);
    const data = {
      organisationId,
      incomingEnable: true,
      outgoingEnable: true,
      outgoingDuration: 24,
      incomingDuration: 5
    };
    POST(`${orgUrl}v1/organisation/sticky-agent`, data)
      .then((res) => {
        fetchData();
        toast.success('Data Reset Successfully');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const fetchData = () => {
    const data = { organisationId }
    GET(`${orgUrl}v1/organisation/sticky-agent`, data)
      .then((res) => {
        const dbData = res?.data?.response?.stickyAgent;
        const { incomingEnable,
          outgoingEnable,
          outgoingDuration,
          incomingDuration } = dbData
        if (outgoingEnable)
          setOutgoingEnable(outgoingEnable);
        if (outgoingDuration)
          setOutgoingDuration(outgoingDuration);
        if (incomingEnable)
          setIncomingEnable(incomingEnable);
        if (incomingDuration)
          setIncomingDuration(incomingDuration);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <BlacklistCard style={{ marginBottom: 20 }}>
      <BlacklistHeader>
        <div>
          <CardTitle>Sticky Agent</CardTitle>
          <CardText>Automatically route repeat callers to the same agent, they previously interacted with.</CardText>
        </div>
      </BlacklistHeader>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        {props?.isOrgOutboundCallEnabled && (
          <div style={{ width: '40%', marginLeft: 15 }}>
            <ToogleDiv>
              <CardTitle>Outgoing</CardTitle>
              <div>
                <input type="checkbox"
                  id="switch"
                  class="checkbox"
                  checked={outgoingEnable}
                  onClick={outgoingEnableHandler}
                />
                <label for="switch"
                  class="toggle">
                </label>
              </div>
            </ToogleDiv>
            <div>
              <div >Duration (in hours)</div>
              <input
                type="number"
                className='InputBox'
                placeholder='in hours'
                value={outgoingDuration}
                onChange={(e) => {
                  setOutgoingDuration(e.target.value);
                  if (e.target.value)
                    setOutgoingEnable(true);
                  if (!e.target.value || e.target.value == "")
                    setOutgoingEnable(false);
                }}
              />
              <div style={{ color: '#f03810' }}>{outgoingError}</div>
            </div>
          </div>
        )}
        <div style={{ width: '40%', marginRight: 15 }}>
          <ToogleDiv>
            <CardTitle>Incoming</CardTitle>
            <div>
              <input
                type="checkbox"
                id="switchIncoming"
                class="checkbox"
                checked={incomingEnable}
                onClick={incomingEnableHandler} />
              <label for="switchIncoming"
                class="toggle" >
              </label>
            </div>
          </ToogleDiv>
          <div>
            <div>Duration (in minutes)</div>
            <input
              type="number"
              className='InputBox'
              placeholder='in minutes'
              value={incomingDuration}
              onChange={(e) => {
                setIncomingDuration(e.target.value);
                if (e.target.value)
                  setIncomingEnable(true);
                if (!e.target.value || e.target.value == "") {
                  setIncomingEnable(false);
                }
              }}
            />
            <div style={{ color: '#f03810' }}>{incomingError}</div>
          </div>
        </div>
      </div >
      <div style={{ width: '100%', marginTop: 40, textAlign: 'center' }}>
        <AddBlackListButton
          style={{ width: '10%', textAlign: 'center', marginRight: 10 }}
          onClick={updateData}
          disabled={!isSuperAdminWithPermission('ADD')}
        >SAVE
        </AddBlackListButton>
        <AddBlackListButton
          style={{ width: '10%', textAlign: 'center' }}
          onClick={resetData}
          disabled={!isSuperAdminWithPermission('ADD')}
        >RESET
        </AddBlackListButton>
      </div>
    </BlacklistCard >
  );
};

// Styled components
const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  width: 41.1%;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor === '#000000' ? 'rgb(55, 58, 60)' : '#ffffff'};
`;

const SettingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border:1px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.acccountsettingBgColor};
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const BlacklistCard = styled(Card)`
  overflow-x: auto;
`;

export const CardTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
`;

export const CardText = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
`;

const CheckboxContainer = styled.div`
  margin-bottom: 10px;
`;

const ToogleDiv = styled.div`
display: flex;
justify-content: space-between;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.primary ? '#1a73e8' : '#fff'};
  color: ${props => props.primary ? '#fff' : '#1a73e8'};
  border: 1px solid #1a73e8;
`;

const BlacklistHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const DispositionButtons = styled.button`
padding: 0px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`

export const AddBlackListButton = styled(DispositionButtons)`
  background-color: #007bff;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  background-color: #196cca;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.4;
  letter-spacing: 0.75px;
  text-align: left;
  color: #fff;
`;

export const AddDispositionButton = styled(DispositionButtons)`
  background-color: #007bff;
  font-family: Montserrat;
  font-size: 15px;
  background-color: #196cca;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.4;
  margin-top: 17%;
  margin-bottom: 3%;
  letter-spacing: 0.75px;
  text-align: left;
  color: #fff;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

export const EditDispositionButton = styled(DispositionButtons)`
  background-color: ${(props) => props.theme.colors.acccountsettingBgColor};
  color:${(props) => props.theme.colors.topBarAccTextColor === '#000000' ? '#196cca' : '#fff'};
  border: 1px solid #007bff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-top: 17%;
  margin-bottom: 3%;
  line-height: 3.4;
  letter-spacing: 0.75px;
  text-align: left;
`;

const TableContainer = styled.div`
max-height: 335px; // Adjust this value to set the desired height
overflow-y: auto;

.table {
  margin-bottom: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

thead th {
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.acccountsettingBgColor};
  z-index: 1;
}
th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #eee;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
}
th {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  background-color: ${(props) => props.theme.colors.acccountsettingBgColor};
  color: ${(props) => props.theme.colors.topBarAccTextColor};
}
`;

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isHideCustomerEnabled: state.topbar.isHideCustomerEnabled,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
});

export default styled(connect(mapStateToProps, {})(CallSettings))`
  .ContentContainer {
    /* width: 98%; */
    /* margin: 1%; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
  }


  .toggle {
    position : relative ;
    display : inline-block;
    width : 50px;
    height : 24px;
    background-color: #ccc;
    border-radius: 30px;
}
      
/* After slide changes */
.toggle:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    top: 2px; 
    left: 2px;
    transition:  all 0.5s;
}
            
/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
    left : 27px; 
}
      
/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle {
    background-color: green;
}
      
/* Checkbox vanished */
.checkbox { 
    display : none;
}

  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
  }

  @media only screen and (max-width: 900px) {
    .ContentContainer {
      width: 98%;
      margin: 1%;
      height: calc(100vh - 100px);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }

  .column {
    float: left;
    width: 25%;
    padding: 0 0px;
  }

  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 0px;
    }
  }

  .AuthSubHeader {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .HeadText {
    font-size: 19px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .CaptionText {
    color: ${(props) => props.theme.colors.tableContentColor};
  }
  .outer-circle {
    width: 45px;
    height: 45px;
    border: 1px solid var(--secondary-color2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.tableContentColor};
  }
    .inner-circle {
      width: 32px;
      height: 32px;
      background: ${(props) => props.theme.colors.tableBgColor};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Montserrat;
      line-height: 1.19;
      text-align: center;
      color: ${(props) => props.theme.colors.tableContentColor};
    }

  .PopupInputContainer {
    margin-top: 15px;
  }

  .InputBox {
    padding: 10px;
    height: 44px;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #939598;
    margin-top: 7px;
    margin-bottom: 7px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthButton {
    width: 30%;
    padding: 15px 50px 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: var(--white-color);
    border-radius: 4px;
    border-width: 0;
  }
  .AuthButtonInvert {
    border: solid 1px #ed3833;
    color: #ed3833;
    background-color: ${(props) => props.theme.colors.topBarBg};
  }
  .AuthButtonSave {
    // border: solid 1px #ed3833;
    color: #fff;
    background-color: ${(props) => props.theme.colors.tabSelectedColor};
  }
  .AddBtn {
    cursor: pointer;
    color: #419bf9;
    width: 100%;
    text-align: left;
    padding-left: 5px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
  }
  .DeleteBtn {
    cursor: pointer;
    right: 8px;
    top: 9px;
    width: 18px;
    height: 18px;
  }
  .ErrorContainer {
    width: 50%;
    min-height: 19px;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 3.92; */
    letter-spacing: normal;
    text-align: left;
    color: var(--red-color);
  }

  .ErrorContainerMargin {
    margin-top: 16px;
    margin-bottom: 18px;
  }

`;